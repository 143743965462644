import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { NextPage } from 'next'

const IndexPage: NextPage = () => {
  const router = useRouter()
  const { query, asPath } = router
  const [index] = Array.isArray(query.index) ? query.index : [query.index]
  useEffect(() => {
    if (!index || index === '/' || index === '/index' || asPath === '/index') {
      router.push('/main')
    }
  }, [asPath, index, router])

  return <div />
}

export default IndexPage
